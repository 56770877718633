import { REHYDRATE } from 'redux-persist/lib/constants';

import orderEnum from './enums/orderEnum';
import tagTypeEnum from './enums/tagTypeEnum';
import * as userActions from './actions/user';

export const STORE_SITE_ID = 12;
export const ORDER_SOURCE = 2;

export const COUNTRY_CODE = 'HK';
export const COUNTRY_CODE_ALPHA3 = 'HKG';
export const CURRENCY_CODE = 'HKD';
export const COUNTRY_NAME = 'Hong Kong';

export const CDN_BASE_URL = 'https://d2ktqvd6mxqehw.cloudfront.net';

export const FACEBOOK_ROUTER_URL = 'https://auth.prod.ggops.net/login/';

export const ECOM_BASE_URL = 'https://ecom.prod.hk.ggops.net/api/';
export const CLOUDFRONT_API_BASE_URL = 'https://d3783vj21ggg1y.cloudfront.net/api/';
export const OAUTH_BASE_URL = 'https://ecom.prod.hk.ggops.net/oauth/';
export const AUTH2_BASE_URL = 'https://auth.prod.ggops.net/api/';
export const PAYMENT_BASE_URL = 'https://payments.prod.hk.ggops.net/api/';
export const LOYALTY_BASE_URL = 'https://app.loyalty.prod.hk.ggops.net/api/';
export const ESRI_GEOCODE_URL = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer';
export const KIOSK_NOTIFY_BASE_URL = 'https://ecom.prod.hk.ggops.net/kiosknotify/';

export const ECOM_API_VERSION = 'v1';
export const OAUTH_API_VERSION = 'v1';
export const AUTH2_API_VERSION = 'v2';
export const PAYMENT_API_VERSION = 'v1';
export const LOYALTY_API_VERSION = 'v2';

export const LOYALTY_REDEEM_IN_STORE_PROMPT_SCAN = false;

/* Whitelist of pages that can be accessed directly. */
export const DIRECT_ACCESS_URLS = [
  '/order',
  '/browser',
  '/popup/payment',
  '/popup/facebook',
  '/reorder',
];

export const SALES_TAX_RATE = 0.00;
export const SALES_TAX_NAME = '';

export const GGLOCATION_LOCATOR_NOTICE = '';

export const PIN_DIGITS = 5;
export const MENU_DETAILS_ADD_SCROLL_DELAY = 200;

export const FACEBOOK_GRAPH_BASE_URL = 'https://graph.facebook.com';

export const SENTRY_KEY = 'e2e57a1e202a43009af81c94100f541d';
export const SENTRY_PROJECT = '6';
export const SENTRY_ENVIRONMENT = 'production';
export const SENTRY_IGNORE_ERROR_MAPPING = {'Failed POST request': ['Invalid promo code', 'Receipt already scanned', 'Please key in a valid phone number', 'Store is closed'], 'Failed GET request': ['Failed to fetch', 'Load failed']};

export const SMARTLOOK_API = 'a146696e367654e0bf549fba7fd2802b24cf75c5';
export const RECAPTCHA_SITE_KEY = '6LersBsiAAAAANmD_noUMpjre0sf04DY7vYwvoRK';

export const GTM_ID = 'GTM-5DRLPH24';
export const GA_ID = 'G-9B78GSE041';
export const GOOGLE_SITE_VERIFICATION_CONTENT = 'Fr5bXnMcyNM-cp9a71i42yNo07mP5Gj4QQqtyxk4J-o';

export const RAVEN_ACTION_TYPE_BLACKLIST = [
  REHYDRATE,
  userActions.PAYMENT_LOGIN_REQUESTED,
];
export const RAVEN_ENDPOINT_BLACKLIST = [
  'changePassword',
  'signUp',
  'login',
];

export const FACEBOOK_PRODUCTION_APP_ID = '1856031981332093';
export const FACEBOOK_DEVELOPMENT_APP_ID = '1856361824632442';
export const FACEBOOK_APP_ID = (process.env.NODE_ENV === 'production' ?
  FACEBOOK_PRODUCTION_APP_ID :
  FACEBOOK_DEVELOPMENT_APP_ID
);

export const PLACEHOLDER_IMAGE = '//d38i5z1l0hzlq4.cloudfront.net/static/home/images/cyo-photo-holder.3455814eec77.jpg';

export const PUSHER_APP_KEY = '06361494123a616b5ab0';
export const PUSHER_SETTINGS = {
  authEndpoint: 'https://ecom.prod.hk.ggops.net/pusher/auth/',
  cluster: 'ap1',
  encrypted: true,
};
export const ECOM_CUSTOMER_PUSHER_CHANNEL = 'gogreen-customer-client';

export const CONTACT_EMAIL = 'support@saladstopgroup.com';

export const FACEBOOK_LINK = 'https://www.facebook.com/SaladStopHK/';
export const INSTAGRAM_LINK = 'https://www.instagram.com/saladstophk/?hl=en';
export const OFFICIAL_SITE_LINK = 'http://saladstop.com.hk/';

export const MAP_DEFAULT_ZOOM = 14;
export const MAP_MIN_ZOOM = 11;
export const MAP_MARKER_ICON = '//d38i5z1l0hzlq4.cloudfront.net/static/home/images/locator-marker.a123293b8fb6.svg';
export const DEFAULT_COORDINATES = {
  lat: 1.306697,
  lng: 103.903508,
};
export const ADDRESS_MAP_ADJUST_RADIUS = 200;
export const DEFAULT_COUNTRY_CODE = '+852';
export const POSTCODE_MIN_LENGTH = 5;
export const POSTCODE_MAX_LENGTH = 7;
export const POSTCODE_ALLOWED_CHARS = '0123456789';

export const ENERGY_ID = 1;

export const INGREDIENT_BASES = {
  216: 1,
  215: 2,
  217: 3,
};

export const CYO_SECTION_ROUTES = [
  {
    path: '/cyo/:menuItemId/1',
    className: 'ShoppingPage',
    caption: 'Change Base',
  },
  {
    path: '/cyo/:menuItemId/2',
    className: 'ShoppingPage',
    caption: 'Change Greens',
  },
  {
    path: '/cyo/:menuItemId/3',
    className: 'ShoppingPage',
    caption: 'Change Toppings',
  },
  {
    path: '/cyo/:menuItemId/4',
    className: 'ShoppingPage',
    caption: 'Change Dressing',
  },
  {
    path: '/cyo/:menuItemId/5',
    className: 'ShoppingPage',
    caption: 'Add Snack?',
  },
  {
    path: '/cyo/:menuItemId/:sectionId',
    className: 'ShoppingPage',
    caption: 'Add More',
  },
];


export const NUTRITIONAL_GROUPS = {
  1: {
    id: 1,
    title: 'Ingredients',
    sectionIds: [1, 2, 3],
  },
  2: {
    id: 2,
    title: 'Dressing',
    sectionIds: [4],
  },
};

export const NUTRIENTS_ROUNDING_SETTINGS = {
  1: 0,
  2: 1,
  3: 1,
  4: 1,
  5: 1,
  6: 0,
  7: 1,
  8: 1,
  9: 1,
  10: 0,
};

export const CYO_SECTIONS_GROUPS = {
  1: {
    id: 1,
    title: 'Base & Greens',
    sectionIds: [1, 2],
  },
  2: {
    id: 2,
    title: null,
    sectionIds: [3],
  },
  3: {
    id: 3,
    title: null,
    sectionIds: [4],
  },
};

export const BASE_SECTION_ID = 1;

export const PROCESSING_TIME_MARGIN = 15;

export const COMPLETED_ORDERS_CUTOFF_TIME = [2, 'hours'];

export const SECTION_SETTINGS = {
  1: {
    ingredientDisplaySize: 'Big',
    inlineIngredientsPicker: false,
    cyoPagination: false,
    hidden: true,
    removeable: false,
    replaceable: false,
    required: false,
    isSectionToggleable: false,
    showDietInformation: false,
    addCategories: {
      premade: [],
      cyo: [8],
    },
    addMultiple: false,
    showDietButton: false,
    isPriceDisplayed: false,
    limit: {
      premade: { section: { number: 0, extra: false } },
      cyo: { section: { number: 0, extra: false } },
    },
  },
  2: {
    ingredientDisplaySize: 'Medium',
    inlineIngredientsPicker: false,
    cyoPagination: false,
    hidden: false,
    removeable: true,
    replaceable: false,
    required: false,
    isSectionToggleable: false,
    showDietInformation: true,
    addCategories: {
      premade: [2],
      cyo: [1, 2],
    },
    addMultiple: false,
    showDietButton: false,
    isPriceDisplayed: false,
    limit: {
      premade: { section: { number: 2, extra: false } },
      cyo: { section: { number: 2, extra: false } },
    },
  },
  3: {
    ingredientDisplaySize: 'Medium',
    inlineIngredientsPicker: false,
    cyoPagination: true,
    hidden: false,
    removeable: true,
    replaceable: false,
    required: false,
    isSectionToggleable: false,
    showDietInformation: false,
    addCategories: {
      premade: [3, 4],
      cyo: [3, 4],
    },
    addMultiple: true,
    showDietButton: true,
    isPriceDisplayed: true,
    limit: {
      premade: { 3: { number: 2, extra: true } },
      cyo: { 3: { number: 6, extra: false } },
    },
  },
  4: {
    ingredientDisplaySize: 'Small',
    inlineIngredientsPicker: false,
    cyoPagination: true,
    hidden: false,
    removeable: false,
    replaceable: true,
    required: false,
    isSectionToggleable: true,
    showDietInformation: false,
    addCategories: {
      premade: [10, 11],
      cyo: [10, 11],
    },
    addMultiple: false,
    showDietButton: true,
    isPriceDisplayed: true,
    limit: {
      premade: { section: { number: 1, extra: false } },
      cyo: { section: { number: 2, extra: false } },
    },
  },
};

export const BASE_SETTINGS = {
  1: {
    sections: {
      1: {},
      2: {},
      3: {
        limit: {
          premade: { 3: { number: 2, extra: true } },
          cyo: { 3: { number: 6, extra: false } },
        },
      },
      4: {},
    },
    premade: false,
    subcategories: false,
  },
  2: {
    sections: {
      1: {
        hidden: false,
        replaceable: true,
        required: true,
        addCategories: {
          cyo: [8],
          premade: [8],
        },
        limit: {
          premade: { section: { number: 1, extra: false } },
          cyo: { section: { number: 1, extra: false } },
        },
      },
      2: {},
      3: {},
      4: {},
    },
    premade: false,
    subcategories: false,
  },
  3: {
    sections: {
      1: {
        hidden: false,
        removeable: true,
        required: true,
        addCategories: {
          cyo: [8],
          premade: [8],
        },
        limit: {
          premade: {},
          cyo: {},
        },
      },
      2: {},
      3: {},
      4: {},
    },
    premade: false,
    subcategories: false,
  },
  4: {
    sections: {
      1: { hidden: true },
      2: { hidden: true },
      3: { hidden: true },
      4: { hidden: true },
    },
    premade: true,
    subcategories: false,
  },
};

export const ORDER_ERROR_INTERPRETATION = {
  [orderEnum.ORDER_ERROR_TIMESLOT_INSTANCE_NOT_FOUND]: 'TIME',
  [orderEnum.ORDER_ERROR_TIMESLOT_SCHEDULE_FULL]: 'TIME',
  [orderEnum.ORDER_ERROR_TIMESLOT_NOT_EXISTS]: 'TIME',
  [orderEnum.ORDER_ERROR_DUE_NOT_AVAILABLE]: 'TIME',
  [orderEnum.ORDER_ERROR_STORE_BUSY]: 'LOCATION',
  [orderEnum.ORDER_ERROR_PARTNER_INVALID]: 'LOCATION',
  [orderEnum.ORDER_ERROR_STORE_INVALID]: 'LOCATION',
  [orderEnum.ORDER_ERROR_ORDER_TYPE_INVALID]: 'LOCATION',
  [orderEnum.ORDER_ERROR_PHONE_INVALID]: 'PHONE_NUMBER',
  [orderEnum.ORDER_ERROR_USER_INVALID]: 'TOKEN',
  [orderEnum.ORDER_ERROR_DUE_INVALID]: 'TIME',
  [orderEnum.ORDER_ERROR_TIMESLOT_INVALID]: 'TIME',
  [orderEnum.ORDER_ERROR_GGLOCATION_INVALID]: 'LOCATION',
};

export const ORDER_DELAY_TIME = 4000;

export const LOYALTY_S3_DOMAIN = 'https://s3-ap-southeast-1.amazonaws.com/gogreen-prod-loyalty/';

export const MOBILE_PREVIEW_NUTRIENTS = [7, 3, 2];

export const RECOMMENDED_GGLOCATIONS_LIMITS = {
  user: [
    { provider: 'mostFrequent', limit: 2 },
    { provider: 'mostRecent', limit: 1 },
  ],
  guest: [
    { provider: 'nearest', limit: 2 },
  ],
  fallback: [
    { provider: 'nextAvailable', limit: 2 },
  ],
};

// Tags that should be apply to ingredient level only
export const INGREDIENT_LEVEL_TAG_TYPES = [tagTypeEnum.ALLERGEN];

// Below is sandbox, production should be https://t.2c2p.com/SecurePayment/api/my2c2p.1.7.3.min.js
// sandbox is https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/api/my2c2p.1.6.9.min.js
// For more details, please view https://developer.2c2p.com/docs/encryption-of-card-data-information#import-2c2p-javascript-sdk
export const PAYMENT_2C2P_CARD_SDK =
  'https://t.2c2p.com/SecurePayment/api/my2c2p.1.7.3.min.js';

export const GOOGLEPAY_PRODUCTION_MERCHANT_ID = 'BCR2DN4TWX2J5LC4';
export const MERCHANT_ID_2C2P = '344344000052000';
export const APPLEPAY_DOMAIN_NAME = 'order.saladstop.com.hk';
